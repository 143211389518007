import React, { useState, useEffect } from "react";
import axios from "axios";
import SearchBar from "../components/SearchBar";
import PlayerCard from "../components/PlayerCard";

// Dynamic backend URL
const backendUrl = process.env.NODE_ENV === "production" ? "https://apex-spectate-backend.azurewebsites.net" : "http://localhost:8080";

function Live() {
  const [games, setGames] = useState([]); // Live games fetched from backend
  const [filteredGames, setFilteredGames] = useState([]); // Filtered games for display
  const [gameTypes, setGameTypes] = useState(["All"]); // Unique game types for dropdown
  const [searchTerm, setSearchTerm] = useState(""); // Search term for player filtering
  const [selectedGameType, setSelectedGameType] = useState("All"); // Selected game type for filtering
  const [expandedCardId, setExpandedCardId] = useState(null); // For expanding cards
  const [selectedTournamentOrTeam, setSelectedTournamentOrTeam] = useState(null); // For TreeSelect filtering (tournament/team)
  const POLLING_INTERVAL = 60000; // Polling interval to fetch live games

  // Fetch live games from the backend
  const fetchLiveGames = async () => {
    try {
      const response = await axios.get(`${backendUrl}/games`);
      const liveGames = response.data.games;

      // Log the entire response to verify structure
      console.log("Live Games Response:", liveGames);

      // Extract game types for the dropdown
      const uniqueGameTypes = [...new Set(liveGames.map((game) => game.queueType))];
      setGameTypes(["All", ...uniqueGameTypes]);

      setGames(liveGames); // Set the live games
    } catch (error) {
      console.error("Error fetching live games:", error);
    }
  };

  // Polling mechanism to fetch live games every 10 seconds
  useEffect(() => {
    fetchLiveGames();
    const interval = setInterval(fetchLiveGames, POLLING_INTERVAL);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  // Reapply filter when games, search term, selected game type, or selected tournament/team changes
  useEffect(() => {
    applyFilter(games, searchTerm, selectedGameType, selectedTournamentOrTeam);
  }, [games, searchTerm, selectedGameType, selectedTournamentOrTeam]);

  // Handle search input, game type, and TreeSelect filter changes
  const handleSearch = (term, gameType) => {
    setSearchTerm(term); // Update search term
    setSelectedGameType(gameType); // Update selected game type
  };

  const handleTreeSelect = (selectedValue) => {
    setSelectedTournamentOrTeam(selectedValue); // Update selected tournament or team from TreeSelect
  };

  // Filter games based on the search term, selected game type, and selected tournament/team
  const applyFilter = (gameList, term, gameType, selectedTreeValue) => {
    console.log("Applying filter:", { term, gameType, selectedTreeValue });
    let filtered = gameList;

    // Filter based on search term and main player (the one whose puuid matches game.puuid)
    if (term) {
      filtered = filtered.filter((game) => {
        const mainPlayer = game.participants.find((participant) => participant.puuid === game.puuid);
        return mainPlayer?.riotId?.toLowerCase().includes(term.toLowerCase());
      });
    }

    // Filter by selected game type
    if (gameType && gameType !== "All") {
      filtered = filtered.filter((game) => game.queueType === gameType);
    }

    // Filter by selected tournament or team (from TreeSelect)
    if (selectedTreeValue) {
      // Extract all checked keys (tournament or team)
      const selectedKeys = Object.keys(selectedTreeValue).filter((key) => selectedTreeValue[key].checked);

      if (selectedKeys.length > 0) {
        filtered = filtered.filter((game) => {
          return selectedKeys.some((key) => {
            // Check if game matches the selected tournament or team
            const isTeamSelected = key.startsWith("team-") && key.slice(5) === game.teamId;
            const isTournamentSelected = key.startsWith("tournament-") && key.slice(11) === game.tournamentId;
            return isTeamSelected || isTournamentSelected;
          });
        });
      }
    }

    console.log("Filtered Games:", filtered); // Log the filtered games
    setFilteredGames(filtered); // Update filtered games for display
  };

  // Expand or collapse a player card
  const handleExpandCard = (cardId) => {
    setExpandedCardId(expandedCardId === cardId ? null : cardId);
  };

  return (
    <div className="p-p-4">
      {/* Search bar with game type dropdown and TreeSelect */}
      <SearchBar onSearch={handleSearch} gameTypes={gameTypes} onTreeSelect={handleTreeSelect} />

      {/* Display filtered game cards */}
      <div className="player-card-container">
        {filteredGames.map((game) => {
          // Main player (the one with the unique puuid from the game itself)
          const mainPlayer = game.participants.find((participant) => participant.puuid === game.puuid);

          if (mainPlayer) {
            return (
              <PlayerCard
                key={game.puuid} // Use game.puuid as the unique key
                game={game} // Pass the full game object instead of just the main player
                isExpanded={expandedCardId === game.puuid}
                onExpand={() => handleExpandCard(game.puuid)}
              />
            );
          } else {
            console.warn("Main player not found for game:", game);
            return null; // Skip if the main player is not found
          }
        })}
      </div>
    </div>
  );
}

export default Live;
