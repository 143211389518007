import React, { useState, useEffect } from "react";
import axios from "axios";
import TournamentSearchBar from "../components/TournamentSearchBar";
import MatchCard from "../components/MatchCard";
import "./Tournament.css";

const backendUrl = process.env.NODE_ENV === "production" ? "https://apex-spectate-backend.azurewebsites.net" : "http://localhost:8080";

const Tournament = () => {
  const [matches, setMatches] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedTournamentTeams, setSelectedTournamentTeams] = useState(null);
  const [treeData, setTreeData] = useState([]); // TreeSelect data for tournaments and teams

  // Fetch matches from the backend
  const fetchMatches = async () => {
    try {
      const response = await axios.get(`${backendUrl}/tournamentgames/matchcards`);
      setMatches(response.data);
    } catch (error) {
      console.error("Error fetching match card data:", error);
    }
  };

  // Fetch matches every minute
  useEffect(() => {
    fetchMatches();
    const intervalId = setInterval(fetchMatches, 60000);
    return () => clearInterval(intervalId);
  }, []);

  // Fetch tree data for TreeSelect from backend
  useEffect(() => {
    const fetchTreeData = async () => {
      try {
        // Fetch tournaments
        const tournamentsResponse = await axios.get(`${backendUrl}/tournaments`);
        const tournaments = tournamentsResponse.data;

        // Resolve team names by fetching each team via /teams/{teamId}
        const promises = tournaments.map(async (tournament) => {
          const teamPromises = tournament.teams.map(async (teamId) => {
            const teamResponse = await axios.get(`${backendUrl}/teams/${teamId}`);
            const team = teamResponse.data;

            // Use shortName for both key and label in the TreeData
            return {
              key: `team-${team.shortName}`,
              label: team.shortName, // Use the team's shortName
            };
          });

          // Use shortName for tournament key and label
          return {
            key: `tournament-${tournament.shortName}`,
            label: tournament.shortName,
            children: await Promise.all(teamPromises), // Resolve all team promises
          };
        });

        const constructedTreeData = await Promise.all(promises);
        setTreeData(constructedTreeData); // Set treeData for TreeSelect
      } catch (error) {
        console.error("Error fetching tree and team data:", error);
      }
    };

    fetchTreeData();
  });

  // Handle search input change
  const handleSearchChange = (searchValue) => {
    setSearch(searchValue);
  };

  // Handle tournament/team selection change
  const handleSelectionChange = (selectedNodes) => {
    console.log("Selected Nodes:", selectedNodes); // Debugging: log the selected nodes
    setSelectedTournamentTeams(selectedNodes);
  };

  // Filter matches based on player search and selected tournament/team
  const filteredMatches = matches.filter((match) => {
    // Extract the keys from the selectedNodes object
    const selectedKeys = Object.keys(selectedTournamentTeams || {});

    // Use the correct properties for tournament and team short names
    const tournamentKey = `tournament-${match.tournamentShortName}`;
    const team1Key = `team-${match.team1ShortName}`;
    const team2Key = `team-${match.team2ShortName}`;

    console.log("Match Tournament Key:", tournamentKey);
    console.log("Match Team1 Key:", team1Key);
    console.log("Match Team2 Key:", team2Key);
    console.log("Selected Keys:", selectedKeys);

    // Check if the match belongs to the selected tournament
    const isInSelectedTournament = selectedKeys.includes(tournamentKey) || selectedKeys.length === 0;

    // Check if the match involves any of the selected teams
    const isInSelectedTeam =
      selectedKeys.some((selectedKey) => {
        return selectedKey === team1Key || selectedKey === team2Key;
      }) || selectedKeys.length === 0; // If no teams are selected, show all matches in the selected tournament

    // The match must either be in the selected tournament and match one of the selected teams
    const matchInSelectedTeamOrTournament = isInSelectedTournament && isInSelectedTeam;

    // Filter based on player search term
    const matchHasSearchedPlayer = match.participants.some((participant) => participant.riotId.toLowerCase().includes(search.toLowerCase()));

    return matchInSelectedTeamOrTournament && matchHasSearchedPlayer;
  });

  return (
    <div className="tournament-page">
      <TournamentSearchBar onSearchChange={handleSearchChange} onSelectionChange={handleSelectionChange} tournaments={treeData} />

      <div className="matches-grid">
        {filteredMatches.length > 0 ? filteredMatches.map((match) => <MatchCard key={match.gameId} match={match} />) : <p>No matches found.</p>}
      </div>
    </div>
  );
};

export default Tournament;

// pushing to azure5
