import React from "react";
import { InputText } from "primereact/inputtext";
import "./PlayerSearchInput.css"; // Use the CSS file for styling

const PlayerSearchInput = ({ value, onChange }) => {
  return (
    <span className="p-input-icon-left" style={{ display: "flex", alignItems: "center", width: "100%" }}>
      <i className="pi pi-search" style={{ paddingLeft: "0.5rem", color: "#757575" }} />
      <InputText value={value} onChange={onChange} placeholder="Search for player" className="p-inputtext" />
    </span>
  );
};

export default PlayerSearchInput;
