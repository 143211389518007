import React from "react";

function TeamInfo({ teamData }) {
  return teamData ? (
    <div style={{ flex: "1", textAlign: "right", marginTop: "2rem" }}>
      {" "}
      {/* Updated marginTop */}
      <h4
        style={{
          margin: "0",
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "1.2rem",
          marginBottom: "0.5rem",
        }}
      >
        {teamData.tournamentShortName}
      </h4>
      <p
        style={{
          margin: "0",
          textAlign: "center",
          fontSize: "1rem",
          color: "#ea2a2a",
        }}
      >
        {teamData.teamShortName}
      </p>
    </div>
  ) : (
    <p>Loading team data...</p>
  );
}

export default TeamInfo;
