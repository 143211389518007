import React from "react";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";

function Actions({ onSpectate, onOPGGClick, overlayPanelRef, player }) {
  return (
    <div className="p-mt-3 p-flex-column p-gap-2" style={{ marginTop: "1rem" }}>
      <div style={{ textAlign: "center", marginBottom: "0.5rem" }}>
        <Button
          label="Spectate with OP.GG"
          className="p-button-outlined"
          onClick={onOPGGClick}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "50px",
          gap: "1rem",
        }}
      >
        <Button
          label="Spectate"
          className="p-button-primary"
          style={{
            flex: 1,
            textAlign: "center",
            backgroundColor: "#ea2a2a",
            borderColor: "#ea2a2a",
            color: "#fff",
          }}
          onClick={onSpectate} // Call the function passed from PlayerCard
        />
        <Button
          icon="pi pi-chevron-down"
          label="More Info"
          className="p-button-secondary"
          style={{ flex: 1, textAlign: "center" }}
          onClick={(e) => overlayPanelRef.current.toggle(e)}
        />
      </div>

      <OverlayPanel ref={overlayPanelRef} style={{ width: "450px" }}>
        {" "}
        {/* Adjust the width here */}
        <div
          className="p-mt-3"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {/* Left side with 5 players */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              marginRight: "1rem",
            }}
          >
            {player.participants.slice(0, 5).map((participant) => (
              <div
                key={participant.puuid}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                {/* Player Image */}
                <img
                  src={`https://cdn.communitydragon.org/latest/champion/${participant.championId}/square`}
                  alt={participant.riotId}
                  style={{ width: "40px", height: "40px", marginRight: "10px" }}
                />
                {/* Left side Player IGN (no alignment changes) */}
                <span style={{ fontSize: "14px" }}>
                  {participant.riotId.split("#")[0]}
                </span>
              </div>
            ))}
          </div>

          {/* Right side with 5 players (IGN first, then image, text aligned to the right) */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              alignItems: "flex-end",
            }}
          >
            {player.participants.slice(5).map((participant) => (
              <div
                key={participant.puuid}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                  width: "100%",
                }}
              >
                {/* Right side Player IGN with right alignment */}
                <span
                  style={{
                    fontSize: "14px",
                    textAlign: "right",
                    marginRight: "10px",
                    flex: 1,
                  }}
                >
                  {participant.riotId.split("#")[0]}
                </span>
                {/* Player Image */}
                <img
                  src={`https://cdn.communitydragon.org/latest/champion/${participant.championId}/square`}
                  alt={participant.riotId}
                  style={{ width: "40px", height: "40px" }}
                />
              </div>
            ))}
          </div>
        </div>
      </OverlayPanel>
    </div>
  );
}

export default Actions;
