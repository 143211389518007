import React from "react";

function Footer() {
  return (
    <footer className="footer">
      <div className="icon-container">
        <a
          href="https://discord.gg/apexesport"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="pi pi-discord"></i>
        </a>
        <a
          href="https://x.com/ApexEsportOrg"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="pi pi-twitter"></i>{" "}
          {/* Use pi-twitter since it's the closest to X */}
        </a>
        <a
          href="https://apexesporthub.ca/#"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="pi pi-link"></i>
        </a>
        <a
          href="https://www.youtube.com/@ApexEsportOrg"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="pi pi-youtube"></i>
        </a>
      </div>
    </footer>
  );
}

export default Footer;
