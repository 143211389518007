import React, { useState, useEffect, useRef } from "react";
import ChampionImage from "./ChampionImage";
import PlayerInfo from "./PlayerInfo";
import TeamInfo from "./TeamInfo";
import Actions from "./Actions";
import { spectateGame, OPGGClick } from "./SpectateService";
import axios from "axios";
import championMap from "../data/championMap";

const backendUrl = process.env.NODE_ENV === "production" ? "https://apex-spectate-backend.azurewebsites.net" : "http://localhost:8080";

function PlayerCard({ game }) {
  const mainPlayer = game.participants.find((p) => p.puuid === game.puuid); // Main player from the game object

  const [duration, setDuration] = useState(Date.now() - game.gameStartTime);
  const [teamData, setTeamData] = useState(null);
  const overlayPanelRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setDuration(Date.now() - game.gameStartTime);
    }, 1000);
    return () => clearInterval(interval);
  }, [game.gameStartTime]);

  // Fetch player info and then fetch team and tournament data based on player.id
  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        // Step 1: Fetch player info using game.puuid
        const player = await axios.get(`${backendUrl}/players/search?puuid=${game.puuid}`);
        if (player && player.data[0].id) {
          // Step 2: Fetch team data using player.id
          const teams = await axios.get(`${backendUrl}/tournamentplayers/${player.data[0].id}`);
          if (teams && teams.data.length > 0) {
            const team = teams.data[0]; // Use the first index for team data

            // Step 3: Fetch tournament data using tournamentId from the team
            const tournament = await axios.get(`${backendUrl}/tournaments/${team.tournamentId}`);
            if (tournament && tournament.data) {
              // Set both team shortName and tournament shortName
              setTeamData({
                teamShortName: team.shortName, // Team shortName
                tournamentShortName: tournament.data.shortName, // Tournament shortName
              });
            }
          }
        } else {
          console.warn("Player data not found for game.puuid:", game.puuid);
        }
      } catch (error) {
        console.error("Error fetching team or tournament data:", error);
      }
    };

    fetchTeamData();
  }, [game.puuid]);

  const minutes = Math.floor(duration / 1000 / 60);
  const seconds = Math.floor((duration / 1000) % 60);
  const formattedDuration = `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

  // Handle Spectate button click
  const handleSpectateClick = () => {
    const gameId = game.gameId;
    const encryptionKey = game.encryptionKey;

    if (!gameId || !encryptionKey) {
      console.error("Game ID or encryption key is missing");
      return;
    }

    spectateGame(gameId, encryptionKey);
  };

  // Handle OPGG button click
  const handleOPGGClick = () => {
    const gameId = game.gameId;
    const encryptionKey = game.encryptionKey;

    if (!gameId || !encryptionKey) {
      console.error("Game ID or encryption key is missing");
      return;
    }

    OPGGClick(gameId, encryptionKey);
  };

  const constructOpggUrl = () => {
    const participant = game.participants.find((p) => p.puuid === game.puuid);
    if (!participant) return "#"; // Fallback if participant not found

    const ign = participant.riotId.split("#")[0];
    const region = participant.riotId.split("#")[1];
    const encodedIGN = encodeURIComponent(ign);

    return `https://www.op.gg/summoners/${region.toLowerCase()}/${encodedIGN}-${region}`;
  };

  return (
    <div
      className="p-card p-shadow-3 player-card"
      style={{
        marginBottom: "1rem",
        padding: "1rem",
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        gap: "1rem",
      }}
    >
      {/* Dynamic OP.GG link with IGN and region */}
      <a
        href={constructOpggUrl()}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          display: "flex",
          alignItems: "center",
          gap: "0.3rem",
          color: "#ea2a2a",
          textDecoration: "none",
        }}
      >
        <span>opgg</span>
        <i className="pi pi-link" style={{ fontSize: "1.2rem" }}></i>
      </a>

      {/* Left Section: Champion Image and Player Info stacked */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          justifyContent: "flex-start",
        }}
      >
        <ChampionImage player={mainPlayer} championMap={championMap} />
        <PlayerInfo player={game} duration={formattedDuration} />
      </div>

      {/* Right Section: Team Info and Action Buttons */}
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <TeamInfo teamData={teamData} />
        <Actions onSpectate={handleSpectateClick} onOPGGClick={handleOPGGClick} overlayPanelRef={overlayPanelRef} player={game} />
      </div>
    </div>
  );
}

export default PlayerCard;
