import React from "react";
import { Menubar } from "primereact/menubar";
import logo from "../assets/Logo.png";

function NavBar() {
  const items = [
    {
      label: "Live",
      icon: "pi pi-fw pi-video",
      command: () => {
        window.location = "/live";
      },
    },
    {
      label: "Tournament",
      icon: "pi pi-fw pi-trophy",
      command: () => {
        window.location = "/tournament";
      },
    },
  ];

  // Create a logo element
  const start = (
    <img
      src={logo}
      alt="Apex Logo"
      height="60" // Adjust height of logo to fit well in the 80px NavBar
      style={{ marginRight: "10px" }}
    />
  );

  return (
    <Menubar
      model={items}
      start={start}
      className="custom-navbar"
      style={{
        height: "80px",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#282828",
        border: "none",
        boxShadow: "none",
      }}
    />
  );
}

export default NavBar;
