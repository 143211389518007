import axios from "axios";

// Function to handle OP.GG spectate button click
export const OPGGClick = (gameId, encryptionKey) => {
  const opggUrl = `opgg://spectate?host=spectator.na1.lol.pvp.net%3A8080&key=${encryptionKey}&gameId=${gameId}&platformId=NA1&game=LOL`;
  console.log(opggUrl);
  window.location.href = opggUrl;
};

// Dynamic backend URL
const backendUrl = process.env.NODE_ENV === "production" ? "https://apex-spectate-backend.azurewebsites.net" : "http://localhost:8080";

// Function to call the backend and download the batch file
export const spectateGame = async (gameId, encryptionKey) => {
  try {
    const encodedEncryptionKey = base64UrlEncode(encryptionKey);

    // Make the API request to the backend
    const response = await axios.get(`${backendUrl}/spectate/${gameId}/${encodedEncryptionKey}`);

    // Base64 URL-safe encoding
    function base64UrlEncode(input) {
      return btoa(input) // Encode to Base64
        .replace(/\+/g, "-") // Replace '+' with '-'
        .replace(/\//g, "_") // Replace '/' with '_'
        .replace(/=+$/, ""); // Remove padding '=' characters
    }

    // Check if the response contains the batch file URL
    if (response.data && response.data.batchFileUrl) {
      let batchFileUrl = response.data.batchFileUrl;

      // Check if the URL is complete or just the path (i.e., missing base URL)
      if (!batchFileUrl.startsWith("https://")) {
        const storageAccountBaseUrl = "https://yourstorageaccount.blob.core.windows.net/"; // Replace with your actual storage account URL
        batchFileUrl = `${storageAccountBaseUrl}${batchFileUrl}`;
      }

      // Trigger file download by creating a temporary link element
      const link = document.createElement("a");
      link.href = batchFileUrl;
      link.setAttribute("download", "spectate.bat"); // Set default file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up the link
    } else {
      console.error("Batch file URL not found in the response");
    }
  } catch (error) {
    console.error("Error initiating spectate:", error);
  }
};
