import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Live from "./pages/Live";
import Tournament from "./pages/Tournament";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer"; // Import Footer
import "./App.css";

function App() {
  return (
    <Router>
      <div className="app">
        <NavBar />
        <div className="main-content">
          <Routes>
            <Route path="/" element={<Live />} />
            <Route path="/live" element={<Live />} />
            <Route path="/tournament" element={<Tournament />} />
          </Routes>
        </div>
        <Footer /> {/* Add Footer here */}
      </div>
    </Router>
  );
}

export default App;
