import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button"; // PrimeReact Button component
import { OverlayPanel } from "primereact/overlaypanel"; // PrimeReact OverlayPanel component
import "./MatchCard.css"; // Importing your CSS for styles
import { spectateGame, OPGGClick } from "./SpectateService.js"; // Adjust the import path to your actual functions

const MatchCard = ({ match }) => {
  const [expanded, setExpanded] = useState(false);
  const [gameTime, setGameTime] = useState("00:00");
  const op = useRef(null);

  // Helper function to get team logo based on teamShortName
  const getTeamLogo = (teamShortName) => {
    try {
      return require(`../assets/Team Logo/${teamShortName}.png`);
    } catch (error) {
      console.warn(`Logo not found for team: ${teamShortName}, using placeholder.`);
      return "https://via.placeholder.com/50"; // Fallback placeholder image
    }
  };

  useEffect(() => {
    const calculateGameTime = () => {
      const currentTime = Date.now(); // Current time in milliseconds
      const gameStartTime = match.gameStartTime; // Already in milliseconds, no need to multiply by 1000

      const gameDurationSeconds = Math.floor((currentTime - gameStartTime) / 1000); // Calculate duration in seconds
      const minutes = Math.floor(gameDurationSeconds / 60);
      const seconds = gameDurationSeconds % 60;

      const formattedTime = `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
      setGameTime(formattedTime);
    };

    calculateGameTime();
    const intervalId = setInterval(calculateGameTime, 1000); // Update every second

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [match.gameStartTime]);

  const toggleMoreInfo = (e) => {
    op.current.toggle(e);
    setExpanded(!expanded);
  };

  const handleSpectateClick = () => {
    spectateGame(match.gameId, match.encryptionKey);
  };

  const handleOPGGClick = () => {
    OPGGClick(match.gameId, match.encryptionKey);
  };

  return (
    <div className="match-card">
      <div className="tournament-short-name">{match.tournamentShortName}</div>

      <div className="match-header">
        <div className="team-info">
          <div className="team">
            <img src={getTeamLogo(match.team1ShortName)} alt={`${match.team1ShortName} Logo`} />
            <p className="team-name">{match.team1ShortName}</p>
            <p className="team-rank">Rank #{match.team1Ranking}</p>
          </div>
          <div className="score">
            <p>{match.score.split(" - ").join(" VS ")}</p>
            <div className="score-time">
              <i className="pi pi-stopwatch" style={{ color: "#ea2a2a", fontSize: "1.4rem", marginRight: "5px" }}></i>
              <span>{gameTime}</span>
            </div>
          </div>
          <div className="team">
            <img src={getTeamLogo(match.team2ShortName)} alt={`${match.team2ShortName} Logo`} />
            <p className="team-name">{match.team2ShortName}</p>
            <p className="team-rank">Rank #{match.team2Ranking}</p>
          </div>
        </div>
      </div>

      <div className="action-buttons">
        <Button label="SPECTATE" className="p-button-danger spectate-btn" onClick={handleSpectateClick} />
        <Button label="SPECTATE WITH OP.GG" className="p-button-outlined p-button-primary opgg-btn" onClick={handleOPGGClick} />
        <Button label="MORE INFO" icon="pi pi-chevron-down" className="p-button-secondary more-info-btn" onClick={toggleMoreInfo} />
      </div>

      <OverlayPanel ref={op} id="overlay_panel" style={{ width: "400px" }}>
        <div className="more-info-panel">
          <div className="banned-champions">
            {/* Left side bans (picks 1-3-5-8-10) */}
            <div className="bans-left">
              {match.bannedChampions
                .filter((_, i) => [0, 2, 4, 7, 9].includes(i))
                .map((ban, index) => (
                  <img key={index} src={ban.championPictureURL} alt={`Ban ${index + 1}`} className="banned-champion-image" />
                ))}
            </div>
            {/* Right side bans (picks 2-4-6-7-9) */}
            <div className="bans-right">
              {match.bannedChampions
                .filter((_, i) => [1, 3, 5, 6, 8].includes(i))
                .map((ban, index) => (
                  <img key={index} src={ban.championPictureURL} alt={`Ban ${index + 1}`} className="banned-champion-image" />
                ))}
            </div>
          </div>

          <div className="player-info">
            {/* Team 1 participants (1-5) */}
            <div className="team-left">
              {match.participants.slice(0, 5).map((participant, index) => (
                <div key={index} className="player">
                  <img src={participant.championPictureURL} alt={participant.riotId} className="participant-champion-image" />
                  <p>{participant.riotId.split("#")[0]}</p>
                </div>
              ))}
            </div>
            {/* Team 2 participants (6-10) */}
            <div className="team-right">
              {match.participants.slice(5, 10).map((participant, index) => (
                <div key={index} className="player">
                  <img src={participant.championPictureURL} alt={participant.riotId} className="participant-champion-image" />
                  <p>{participant.riotId.split("#")[0]}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </OverlayPanel>
    </div>
  );
};

export default MatchCard;
