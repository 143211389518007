import React from "react";

function ChampionImage({ player, championMap }) {
  const championData = championMap[player.championId];
  const championImage = championData
    ? `https://ddragon.leagueoflegends.com/cdn/14.21.1/img/champion/${championData.image}`
    : "https://ddragon.leagueoflegends.com/cdn/14.21.1/img/champion/default.png";

  return (
    <img
      src={championImage}
      alt={championData ? championData.name : "Champion"}
      style={{
        width: "100px",
        height: "100px",
        objectFit: "cover",
        marginBottom: "1rem",
      }}
    />
  );
}

export default ChampionImage;
