import React from "react";
import { TreeSelect } from "primereact/treeselect";
import "./TournamentTeamTreeSelect.css"; // Use the CSS file for styling

const TournamentTeamTreeSelect = ({ value, options, onChange }) => {
  return (
    <div className="tournament-team-treeselect">
      <TreeSelect
        value={value}
        options={options}
        onChange={onChange}
        placeholder="Select Tournament & Teams"
        className="w-full"
        selectionMode="checkbox"
        display="chip" // Display selected nodes as chips
        showClear
      />
    </div>
  );
};

export default TournamentTeamTreeSelect;
