import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { TreeSelect } from "primereact/treeselect"; // TreeSelect for Tournament/Team filtering
import axios from "axios";

function SearchBar({ onSearch, gameTypes, onTreeSelect }) {
  // Add onTreeSelect prop
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedGameType, setSelectedGameType] = useState("All");
  const [treeData, setTreeData] = useState([]); // TreeSelect data for tournaments and teams
  const [selectedNodes, setSelectedNodes] = useState({}); // Selected nodes for multiple checkbox selection

  const backendUrl = process.env.NODE_ENV === "production" ? "https://apex-spectate-backend.azurewebsites.net" : "http://localhost:8080";

  // Fetch tree data for TreeSelect from backend
  useEffect(() => {
    const fetchTreeData = async () => {
      try {
        // Fetch tournaments
        const tournamentsResponse = await axios.get(`${backendUrl}/tournaments`);
        const tournaments = tournamentsResponse.data;

        // Resolve team names by fetching each team via /teams/{teamId}
        const promises = tournaments.map(async (tournament) => {
          const teamPromises = tournament.teams.map(async (teamId) => {
            const teamResponse = await axios.get(`${backendUrl}/teams/${teamId}`);
            const team = teamResponse.data;

            // Return team with resolved name
            return {
              key: `team-${team.id}`,
              label: team.shortName, // Use the team's shortName
            };
          });

          // Return tournament with its teams
          return {
            key: `tournament-${tournament.id}`,
            label: tournament.shortName,
            children: await Promise.all(teamPromises), // Resolve all team promises
          };
        });

        const constructedTreeData = await Promise.all(promises);
        setTreeData(constructedTreeData); // Set treeData for TreeSelect
      } catch (error) {
        console.error("Error fetching tree and team data:", error);
      }
    };

    fetchTreeData();
  }, [backendUrl]);

  // Handler for search term change
  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
    onSearch(e.target.value, selectedGameType);
  };

  // Handler for game type change
  const handleGameTypeChange = (e) => {
    setSelectedGameType(e.value);
    onSearch(searchTerm, e.value);
  };

  // Handler for TreeSelect (tournament/team) selection
  const handleNodeSelect = (e) => {
    setSelectedNodes(e.value);
    onTreeSelect(e.value); // Pass selected tournament or team to parent (Live.js)
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "1rem", padding: "1rem 0" }}>
      {/* Search Player Name Input */}
      <div style={{ flex: "3", maxWidth: "25%" }}>
        <span className="p-input-icon-left" style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <i className="pi pi-search" style={{ paddingLeft: "0.5rem", color: "#757575" }} />
          <InputText
            value={searchTerm}
            onChange={handleSearchTermChange}
            placeholder="Search player name"
            style={{ width: "100%", paddingLeft: "2rem" }}
          />
        </span>
      </div>

      {/* Game Type Dropdown */}
      <div style={{ flex: "1", maxWidth: "20%" }}>
        <Dropdown
          value={selectedGameType}
          options={gameTypes.map((type) => ({ label: type, value: type }))}
          onChange={handleGameTypeChange}
          placeholder="Select Game Type"
          style={{ width: "100%" }}
        />
      </div>

      {/* TreeSelect for Tournament/Team filtering */}
      <div style={{ flex: "2", maxWidth: "25%" }}>
        <TreeSelect
          value={selectedNodes}
          options={treeData}
          onChange={handleNodeSelect} // Call the handler when a tournament/team is selected
          placeholder="Select Tournament/Team"
          className="w-full"
          selectionMode="checkbox" // Enable checkbox selection for multiple nodes
          display="chip" // Display selected nodes as chips
          showClear={true} // Add clear icon to reset the selection
        />
      </div>
    </div>
  );
}

export default SearchBar;
