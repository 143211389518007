const championMap = {
  266: { name: "Aatrox", image: "Aatrox.png" },
  103: { name: "Ahri", image: "Ahri.png" },
  84: { name: "Akali", image: "Akali.png" },
  166: { name: "Akshan", image: "Akshan.png" },
  12: { name: "Alistar", image: "Alistar.png" },
  32: { name: "Amumu", image: "Amumu.png" },
  34: { name: "Anivia", image: "Anivia.png" },
  1: { name: "Annie", image: "Annie.png" },
  523: { name: "Aphelios", image: "Aphelios.png" },
  22: { name: "Ashe", image: "Ashe.png" },
  136: { name: "Aurelion Sol", image: "AurelionSol.png" },
  893: { name: "Aurora", image: "Aurora.png" },
  268: { name: "Azir", image: "Azir.png" },
  432: { name: "Bard", image: "Bard.png" },
  200: { name: "Bel'Veth", image: "Belveth.png" },
  53: { name: "Blitzcrank", image: "Blitzcrank.png" },
  63: { name: "Brand", image: "Brand.png" },
  201: { name: "Braum", image: "Braum.png" },
  233: { name: "Briar", image: "Briar.png" },
  51: { name: "Caitlyn", image: "Caitlyn.png" },
  164: { name: "Camille", image: "Camille.png" },
  69: { name: "Cassiopeia", image: "Cassiopeia.png" },
  31: { name: "Cho'Gath", image: "Chogath.png" },
  42: { name: "Corki", image: "Corki.png" },
  122: { name: "Darius", image: "Darius.png" },
  131: { name: "Diana", image: "Diana.png" },
  119: { name: "Draven", image: "Draven.png" },
  36: { name: "Dr. Mundo", image: "DrMundo.png" },
  245: { name: "Ekko", image: "Ekko.png" },
  60: { name: "Elise", image: "Elise.png" },
  28: { name: "Evelynn", image: "Evelynn.png" },
  81: { name: "Ezreal", image: "Ezreal.png" },
  9: { name: "Fiddlesticks", image: "Fiddlesticks.png" },
  114: { name: "Fiora", image: "Fiora.png" },
  105: { name: "Fizz", image: "Fizz.png" },
  3: { name: "Galio", image: "Galio.png" },
  41: { name: "Gangplank", image: "Gangplank.png" },
  86: { name: "Garen", image: "Garen.png" },
  150: { name: "Gnar", image: "Gnar.png" },
  79: { name: "Gragas", image: "Gragas.png" },
  104: { name: "Graves", image: "Graves.png" },
  887: { name: "Gwen", image: "Gwen.png" },
  120: { name: "Hecarim", image: "Hecarim.png" },
  74: { name: "Heimerdinger", image: "Heimerdinger.png" },
  910: { name: "Hwei", image: "Hwei.png" },
  420: { name: "Illaoi", image: "Illaoi.png" },
  39: { name: "Irelia", image: "Irelia.png" },
  427: { name: "Ivern", image: "Ivern.png" },
  40: { name: "Janna", image: "Janna.png" },
  59: { name: "Jarvan IV", image: "JarvanIV.png" },
  24: { name: "Jax", image: "Jax.png" },
  126: { name: "Jayce", image: "Jayce.png" },
  202: { name: "Jhin", image: "Jhin.png" },
  222: { name: "Jinx", image: "Jinx.png" },
  145: { name: "Kai'Sa", image: "Kaisa.png" },
  429: { name: "Kalista", image: "Kalista.png" },
  43: { name: "Karma", image: "Karma.png" },
  30: { name: "Karthus", image: "Karthus.png" },
  38: { name: "Kassadin", image: "Kassadin.png" },
  55: { name: "Katarina", image: "Katarina.png" },
  10: { name: "Kayle", image: "Kayle.png" },
  141: { name: "Kayn", image: "Kayn.png" },
  85: { name: "Kennen", image: "Kennen.png" },
  121: { name: "Kha'Zix", image: "Khazix.png" },
  203: { name: "Kindred", image: "Kindred.png" },
  240: { name: "Kled", image: "Kled.png" },
  96: { name: "Kog'Maw", image: "KogMaw.png" },
  897: { name: "K'Sante", image: "KSante.png" },
  7: { name: "LeBlanc", image: "Leblanc.png" },
  64: { name: "Lee Sin", image: "LeeSin.png" },
  89: { name: "Leona", image: "Leona.png" },
  876: { name: "Lillia", image: "Lillia.png" },
  127: { name: "Lissandra", image: "Lissandra.png" },
  236: { name: "Lucian", image: "Lucian.png" },
  117: { name: "Lulu", image: "Lulu.png" },
  99: { name: "Lux", image: "Lux.png" },
  54: { name: "Malphite", image: "Malphite.png" },
  90: { name: "Malzahar", image: "Malzahar.png" },
  57: { name: "Maokai", image: "Maokai.png" },
  11: { name: "Master Yi", image: "MasterYi.png" },
  902: { name: "Milio", image: "Milio.png" },
  21: { name: "Miss Fortune", image: "MissFortune.png" },
  62: { name: "Wukong", image: "MonkeyKing.png" },
  82: { name: "Mordekaiser", image: "Mordekaiser.png" },
  25: { name: "Morgana", image: "Morgana.png" },
  950: { name: "Naafiri", image: "Naafiri.png" },
  267: { name: "Nami", image: "Nami.png" },
  75: { name: "Nasus", image: "Nasus.png" },
  111: { name: "Nautilus", image: "Nautilus.png" },
  518: { name: "Neeko", image: "Neeko.png" },
  76: { name: "Nidalee", image: "Nidalee.png" },
  895: { name: "Nilah", image: "Nilah.png" },
  56: { name: "Nocturne", image: "Nocturne.png" },
  20: { name: "Nunu & Willump", image: "Nunu.png" },
  2: { name: "Olaf", image: "Olaf.png" },
  61: { name: "Orianna", image: "Orianna.png" },
  516: { name: "Ornn", image: "Ornn.png" },
  80: { name: "Pantheon", image: "Pantheon.png" },
  78: { name: "Poppy", image: "Poppy.png" },
  555: { name: "Pyke", image: "Pyke.png" },
  246: { name: "Qiyana", image: "Qiyana.png" },
  133: { name: "Quinn", image: "Quinn.png" },
  497: { name: "Rakan", image: "Rakan.png" },
  33: { name: "Rammus", image: "Rammus.png" },
  421: { name: "Rek'Sai", image: "RekSai.png" },
  526: { name: "Rell", image: "Rell.png" },
  888: { name: "Renata Glasc", image: "Renata.png" },
  58: { name: "Renekton", image: "Renekton.png" },
  107: { name: "Rengar", image: "Rengar.png" },
  92: { name: "Riven", image: "Riven.png" },
  68: { name: "Rumble", image: "Rumble.png" },
  13: { name: "Ryze", image: "Ryze.png" },
  360: { name: "Samira", image: "Samira.png" },
  113: { name: "Sejuani", image: "Sejuani.png" },
  235: { name: "Senna", image: "Senna.png" },
  147: { name: "Seraphine", image: "Seraphine.png" },
  875: { name: "Sett", image: "Sett.png" },
  35: { name: "Shaco", image: "Shaco.png" },
  98: { name: "Shen", image: "Shen.png" },
  102: { name: "Shyvana", image: "Shyvana.png" },
  27: { name: "Singed", image: "Singed.png" },
  14: { name: "Sion", image: "Sion.png" },
  15: { name: "Sivir", image: "Sivir.png" },
  72: { name: "Skarner", image: "Skarner.png" },
  901: { name: "Smolder", image: "Smolder.png" },
  37: { name: "Sona", image: "Sona.png" },
  16: { name: "Soraka", image: "Soraka.png" },
  50: { name: "Swain", image: "Swain.png" },
  517: { name: "Sylas", image: "Sylas.png" },
  134: { name: "Syndra", image: "Syndra.png" },
  223: { name: "Tahm Kench", image: "TahmKench.png" },
  163: { name: "Taliyah", image: "Taliyah.png" },
  91: { name: "Talon", image: "Talon.png" },
  44: { name: "Taric", image: "Taric.png" },
  17: { name: "Teemo", image: "Teemo.png" },
  412: { name: "Thresh", image: "Thresh.png" },
  18: { name: "Tristana", image: "Tristana.png" },
  48: { name: "Trundle", image: "Trundle.png" },
  23: { name: "Tryndamere", image: "Tryndamere.png" },
  4: { name: "Twisted Fate", image: "TwistedFate.png" },
  29: { name: "Twitch", image: "Twitch.png" },
  77: { name: "Udyr", image: "Udyr.png" },
  6: { name: "Urgot", image: "Urgot.png" },
  110: { name: "Varus", image: "Varus.png" },
  67: { name: "Vayne", image: "Vayne.png" },
  45: { name: "Veigar", image: "Veigar.png" },
  161: { name: "Vel'Koz", image: "Velkoz.png" },
  711: { name: "Vex", image: "Vex.png" },
  254: { name: "Vi", image: "Vi.png" },
  234: { name: "Viego", image: "Viego.png" },
  112: { name: "Viktor", image: "Viktor.png" },
  8: { name: "Vladimir", image: "Vladimir.png" },
  106: { name: "Volibear", image: "Volibear.png" },
  19: { name: "Warwick", image: "Warwick.png" },
  498: { name: "Xayah", image: "Xayah.png" },
  101: { name: "Xerath", image: "Xerath.png" },
  5: { name: "Xin Zhao", image: "XinZhao.png" },
  157: { name: "Yasuo", image: "Yasuo.png" },
  777: { name: "Yone", image: "Yone.png" },
  83: { name: "Yorick", image: "Yorick.png" },
  350: { name: "Yuumi", image: "Yuumi.png" },
  154: { name: "Zac", image: "Zac.png" },
  238: { name: "Zed", image: "Zed.png" },
  221: { name: "Zeri", image: "Zeri.png" },
  115: { name: "Ziggs", image: "Ziggs.png" },
  26: { name: "Zilean", image: "Zilean.png" },
  142: { name: "Zoe", image: "Zoe.png" },
  143: { name: "Zyra", image: "Zyra.png" },
};

export default championMap;
