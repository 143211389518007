import React, { useState } from "react";
import PlayerSearchInput from "./PlayerSearchInput";
import TournamentTeamTreeSelect from "./TournamentTeamTreeSelect";
import "./TournamentSearchBar.css"; // Applying new layout and styles

const TournamentSearchBar = ({ onSearchChange, onSelectionChange, tournaments }) => {
  const [searchValue, setSearchValue] = useState("");
  const [selectedTeams, setSelectedTeams] = useState(null);

  // Handle player search input change
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    onSearchChange(e.target.value); // Ensure the parent gets the updated value
  };

  // Handle TreeSelect change for tournaments and teams
  const handleSelectionChange = (e) => {
    setSelectedTeams(e.value);
    onSelectionChange(e.value);
  };

  return (
    <div className="search-bar">
      <div style={{ flex: "3", maxWidth: "25%" }}>
        <PlayerSearchInput value={searchValue} onChange={handleSearchChange} /> {/* Pass down value and onChange */}
      </div>
      <div style={{ flex: "2", maxWidth: "25%" }}>
        <TournamentTeamTreeSelect value={selectedTeams} options={tournaments} onChange={handleSelectionChange} />
      </div>
    </div>
  );
};

export default TournamentSearchBar;
