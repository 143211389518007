import React from "react";

function PlayerInfo({ player, duration }) {
  return (
    <div
      style={{
        flex: "1",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      <h3 style={{ margin: "0" }}>
        {
          player.participants
            .find((participant) => participant.puuid === player.puuid)
            ?.riotId.split("#")[0]
        }
      </h3>
      <p style={{ marginRight: "0", marginTop: "8px", marginBottom: "0" }}>
        {player.queueType}
      </p>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          marginTop: "8px",
        }}
      >
        <i className="pi pi-stopwatch" style={{ color: "#ea2a2a" }}></i>
        <p style={{ margin: "0" }}>{duration}</p>
      </div>
    </div>
  );
}

export default PlayerInfo;
